import { SECONDARY_MAIN_COLOR_LIGHT } from '../../constants';
import { muiCssBaseline } from './muiCssBaseline';

export const overrides = {
  muiCssBaseline,
  MuiButton: {
    containedSecondary: {
      '&:hover': {
        backgroundColor: SECONDARY_MAIN_COLOR_LIGHT,
      },
    },
  },
};
