import { COLOR_WHITE } from '../../constants';

export const muiCssBaseline = {
  '@global': {
    html: {
      height: '100%',
    },
    body: {
      height: '100%',
      backgroundColor: COLOR_WHITE,
    },
    'body #app': {
      height: '100%',
    },
  },
};
