import { createTheme as createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { typography } from './themeParts/typography';
import { palette } from './themeParts/palette';
import { overrides } from './themeParts/overrides';

export const createTheme = () => {
  let themeDefinition = createMuiTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1440,
      },
    },
    typography,
    palette,
    overrides,
  });
  themeDefinition = responsiveFontSizes(themeDefinition);
  return themeDefinition;
};
