// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-agb-jsx": () => import("./../../../src/pages/agb.jsx" /* webpackChunkName: "component---src-pages-agb-jsx" */),
  "component---src-pages-calculator-jsx": () => import("./../../../src/pages/calculator.jsx" /* webpackChunkName: "component---src-pages-calculator-jsx" */),
  "component---src-pages-contact-feedback-registration-jsx": () => import("./../../../src/pages/contact-feedback-registration.jsx" /* webpackChunkName: "component---src-pages-contact-feedback-registration-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-employee-instruction-jsx": () => import("./../../../src/pages/employee-instruction.jsx" /* webpackChunkName: "component---src-pages-employee-instruction-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-forklift-drivers-instruction-jsx": () => import("./../../../src/pages/forklift-drivers-instruction.jsx" /* webpackChunkName: "component---src-pages-forklift-drivers-instruction-jsx" */),
  "component---src-pages-how-to-create-an-instruction-jsx": () => import("./../../../src/pages/how-to-create-an-instruction.jsx" /* webpackChunkName: "component---src-pages-how-to-create-an-instruction-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-initial-instruction-jsx": () => import("./../../../src/pages/initial-instruction.jsx" /* webpackChunkName: "component---src-pages-initial-instruction-jsx" */),
  "component---src-pages-instruction-archive-jsx": () => import("./../../../src/pages/instruction-archive.jsx" /* webpackChunkName: "component---src-pages-instruction-archive-jsx" */),
  "component---src-pages-intro-jsx": () => import("./../../../src/pages/intro.jsx" /* webpackChunkName: "component---src-pages-intro-jsx" */),
  "component---src-pages-legal-notice-jsx": () => import("./../../../src/pages/legal-notice.jsx" /* webpackChunkName: "component---src-pages-legal-notice-jsx" */),
  "component---src-pages-personnel-hygiene-jsx": () => import("./../../../src/pages/personnel-hygiene.jsx" /* webpackChunkName: "component---src-pages-personnel-hygiene-jsx" */),
  "component---src-pages-prices-jsx": () => import("./../../../src/pages/prices.jsx" /* webpackChunkName: "component---src-pages-prices-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-product-jsx": () => import("./../../../src/pages/product.jsx" /* webpackChunkName: "component---src-pages-product-jsx" */),
  "component---src-pages-product-old-jsx": () => import("./../../../src/pages/product-old.jsx" /* webpackChunkName: "component---src-pages-product-old-jsx" */),
  "component---src-pages-proof-of-instruction-jsx": () => import("./../../../src/pages/proof-of-instruction.jsx" /* webpackChunkName: "component---src-pages-proof-of-instruction-jsx" */),
  "component---src-pages-register-process-jsx": () => import("./../../../src/pages/register-process.jsx" /* webpackChunkName: "component---src-pages-register-process-jsx" */),
  "component---src-pages-safety-instruction-jsx": () => import("./../../../src/pages/safety-instruction.jsx" /* webpackChunkName: "component---src-pages-safety-instruction-jsx" */),
  "component---src-pages-visitor-instruction-jsx": () => import("./../../../src/pages/visitor-instruction.jsx" /* webpackChunkName: "component---src-pages-visitor-instruction-jsx" */)
}

