/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
// gatsby-browser.js
export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (location.hash) {
    return location.hash.replace('#', '');
  }

  return true;
};

export { wrapRootElement } from './src/theme';
