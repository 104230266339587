import { FONT_FAMILY } from '../constants';

export const typography = {
  fontFamily: FONT_FAMILY,
  h1: {
    fontSize: '2.5rem',
    fontWeight: 400,
    position: 'relative',
  },
  h2: {
    fontSize: '2rem',
    fontWeight: 400,
    position: 'relative',
  },
  h3: {
    fontWeight: 500,
    fontSize: '1.4rem',
    position: 'relative',
  },
  h4: {
    fontWeight: 500,
    fontSize: '1rem',
    position: 'relative',
  },
  h5: {
    fontWeight: 500,
    fontSize: '0.85rem',
    position: 'relative',
  },
  h6: {
    fontWeight: 500,
    fontSize: '0.6rem',
    position: 'relative',
  },
  subtitle1: {
    fontWeight: 600,
    fontSize: '0.85rem',
    position: 'relative',
  },
  subtitle2: {
    fontWeight: 600,
    fontSize: '0.6rem',
    position: 'relative',
  },
};
