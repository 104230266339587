export const PRIMARY_MAIN_COLOR = '#032863';
export const PRIMARY_MAIN_COLOR_LIGHT = '#04409e';
export const PRIMARY_MAIN_COLOR_DARK = '#022153';

export const PRIMARY_TEXT_COLOR = '#1D1E1C';
export const PRIMARY_TEXT_COLOR_LIGHT = '#444543';
export const PRIMARY_TEXT_COLOR_DARK = '#000';

export const SECONDARY_MAIN_COLOR = '#ea5b27';
export const SECONDARY_MAIN_COLOR_LIGHT = '#ff8c54';
export const SECONDARY_MAIN_COLOR_DARK = '#b02800';

export const SECONDARY_TEXT_COLOR = '#989898';
export const SECONDARY_TEXT_COLOR_LIGHT = '#c9c9c9';
export const SECONDARY_TEXT_COLOR_DARK = '#6a6a6a';

export const SECONDARY_BACKGROUND = '#ebf0f4';

export const TRANSPARENT = 'rgba(0, 0, 0, 0)';
export const SEMI_GRAY_TRANSPARENT = 'rgba(0, 0, 0, 0.02)';
export const ULTRA_GRAY_TRANSPARENT = 'rgba(0, 0, 0, 0.15)';
export const COLOR_RED = '#f44336';
export const COLOR_GREEN = '#048939';

export const COLOR_CARD_WHITE = '#fff';
export const COLOR_WHITE = '#fcfcfc';

export const THEME_SPACING = 8;

export const FONT_FAMILY = [
  'Montserrat',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',');

export const NAVBAR_HEIGHT = 75;
